<template>
  <div class="relative">
    <Trigger
      slot="trigger"
      @click="toggleNotification"
      @keydown.enter="toggleNotification"
    >
      <PhBellSimple weight="bold" size="1.2em" role="presentation" />
    </Trigger>
    <NotificationDialog v-if="showNotification" :base-url="baseUrl" />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { PhBellSimple } from "@phosphor-icons/vue";
import Trigger from "./Trigger.vue";
import NotificationDialog from "./NotificationDialog.vue";

const { baseUrl = "" } = defineProps<{
  baseUrl: string;
}>();

const showNotification = ref(false);

const toggleNotification = () => {
  showNotification.value = !showNotification.value;
};
</script>
