import { z } from "zod";
import { UserConnecte } from "@/entities/user/model/User";
import { HelpCategory } from "@/features/helpMenu/model/HelpCategory";
import { Links } from "@/features/links/model/Links";

export const EnvironmentVariables = z.object({
  utilisateurConnecte: UserConnecte.optional(),
  helpMenu: z.array(HelpCategory),
  links: z.array(Links),
  "tb-url": z.string(),
});

export type EnvironmentVariables = z.infer<typeof EnvironmentVariables>;
