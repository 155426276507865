import { z } from "zod";
import { HelpItem } from "./HelpItem";

export const HelpCategory = z.object({
  name: z.string(),
  icon: z.string(),
  items: z.preprocess(
    (data) => (Array.isArray(data) ? data : Array.of(data)),
    z.array(HelpItem),
  ),
});
export type HelpCategory = z.infer<typeof HelpCategory>;
