<template>
  <ItsDialog
    :modal="false"
    :visible="true"
    :position="['3.5em', '3.5em', 'auto', 'auto']"
    :showHeader="false"
    :showFooter="false"
    class="notification-dialog"
  >
    <ItsLoader teleportTarget=".notification-dialog" v-if="showLoader" />
    <iframe ref="iframe" :src="buildUrl" frameborder="0"></iframe>
  </ItsDialog>
</template>

<script setup lang="ts">
import { ItsDialog, ItsLoader } from "@developpeur/core-lib-front";
import { computed, onBeforeUnmount, onMounted, ref, useTemplateRef } from "vue";

const { baseUrl = "" } = defineProps<{
  baseUrl: string;
}>();

const iframe = useTemplateRef("iframe");
const showLoader = ref(true);
const disableLoader = () => {
  showLoader.value = false;
};

const buildUrl = computed(() => {
  return `${baseUrl}/notification/listingIframe`;
});

onMounted(() => {
  iframe.value?.addEventListener("load", disableLoader);
});

onBeforeUnmount(() => {
  iframe.value?.removeEventListener("load", disableLoader);
});
</script>

<style lang="scss">
@use "./NotificationDialog.scss";
</style>
