<template>
  <div class="hover:bg-[#E5F4FF] py-4" :class="{ 'border-t': !isFirst }">
    <div class="flex gap-4 px-4 items-center text-[--its-color-primary-500]">
      <component :is="icon" weight="fill" size="1.8em" />
      <span>{{ category.name }}</span>
    </div>
    <HelpItem
      v-for="(item, index) in category.items"
      :item="item"
      :key="index"
    />
  </div>
</template>

<script setup lang="ts">
// TODO : use EnvironmentVariables.kitGraphique.background_blue	in hover effect
import { HelpCategory } from "@/features/helpMenu/model/HelpCategory";
import {
  PhCube,
  PhGraduationCap,
  PhHeadset,
  PhShieldCheckered,
} from "@phosphor-icons/vue";
import { computed } from "vue";
import HelpItem from "./HelpItem.vue";

const props = defineProps<{
  category: HelpCategory;
  isFirst: boolean;
}>();

const icon = computed(() => {
  switch (props.category.icon) {
    case "graduation-cap":
      return PhGraduationCap;
    case "headset":
      return PhHeadset;
    case "shield-checkered":
      return PhShieldCheckered;
    case "cube":
      return PhCube;
  }
});
</script>
