import { z } from "zod";

export const UserConnecte = z.object({
  id: z.coerce.number(),
  nom: z.string(),
  prenom: z.string(),
  avatar: z.string().optional(),
});

export type UserConnecte = z.infer<typeof UserConnecte>;
