import { ItsUseTeambrain } from "@developpeur/core-lib-front";
import { onMounted } from "vue";

const { setupTeamBrain } = ItsUseTeambrain();
let isInitialized = false;

const initStyle = () => {
  const style = `
    :root {
      .tb-ae-iconBox {
        visibility: hidden;
      }

      .tb-ae-messagesContainer {
        visibility: hidden;
      }
    }
  `;
  const styleSheet = new CSSStyleSheet();
  styleSheet.replaceSync(style);
  document.adoptedStyleSheets = [...document.adoptedStyleSheets, styleSheet];
};

const activate = () => {
  const iframe = document.querySelector<HTMLIFrameElement>(
    ".tb-ae-iconContainer iframe",
  );
  if (!iframe) {
    console.error("Teambrain iframe is missing");
    return;
  }

  if (!iframe.contentWindow) {
    console.error("Teambrain content window is missing");
    return;
  }

  const icon =
    iframe.contentWindow.document.querySelector<HTMLElement>(
      ".tb-ae-icon-flex",
    );
  if (!icon) {
    console.error("Teambrain icon not found");
    return;
  }

  icon.click();
};

function initTeamBrain(url: string) {
  if (isInitialized) return;
  if (!URL.canParse(url)) return;
  setupTeamBrain(url);
  isInitialized = true;
}

export function useCustomTeamBrain() {
  onMounted(() => {
    initStyle();
  });

  return {
    initTeamBrain,
    activate,
  };
}
