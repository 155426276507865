import { z } from "zod";

export const Links = z.object({
  id: z.coerce.number(),
  url: z.string(),
  image: z.string(),
  titre: z.string().nullable(),
});

export type Links = z.infer<typeof Links>;
